<template>
  <div class="app-calendar overflow-hidden border mb-2">
    <div class="row no-gutters">
      <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
          style="max-width: 20%"
      >
        <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
          <div class="p-1">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"
                :aria-expanded="String(isEventHandlerSidebarActive)"
                variant="primary"
                block
                v-b-toggle.etkinlik-sidebar
                size="sm"
            >
              Etkinlik Ekle
            </b-button>
            <div class="mt-1">
              <h5 class="app-label section-label mb-1">
                <span class="align-middle">Kategoriler</span>
              </h5>
              <b-form-checkbox
                  v-model="checkAll"
                  class="mb-1"
              >
                Hepsini Göster
              </b-form-checkbox>
              <b-form-group>
                <b-form-checkbox-group
                    v-model="selectedKategori"
                    name="event-filter"
                    stacked
                >
                  <b-form-checkbox
                      v-for="item in sidebar.kategoriler"
                      :key="item.label"
                      name="event-filter"
                      class="mb-1"
                      :class="`custom-control-${item.color}`"
                      :value="item.label"
                  >
                    {{ item.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <b-img :src="require('@/assets/images/pages/calendar-illustration.png')"/>
        </div>
      </div>
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
            >
              <template v-slot:eventContent="val">
                <b>{{ val.event.extendedProps.baslik }}</b>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
      <b-sidebar
          id="etkinlik-sidebar"
          v-model="isSidebar"
          bg-variant="white"
          right
          backdrop
          shadow
          no-header
      >
        <template #default="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              {{ !isEdit ? 'Etkinlik Ekle' : 'Düzenleniyor' }}
            </h5>
            <div>
              <font-awesome-icon icon="fa-solid fa-trash-can" class="cursor-pointer" @click="etkinlikDelete"
                                 v-if="isEdit"
              />
              <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
              />
            </div>
          </div>
          <b-row class="m-50">
            <b-col cols="12">
              <b-form-group
                  label="Başlık"
                  label-for="basicInput"
              >
                <b-form-input
                    id="basicInput"
                    placeholder="Başlık Giriniz"
                    autocomplete="off"
                    v-model="sidebar.baslik"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Kategori"
                  label-for="kategori"
              >
                <v-select
                    v-model="sidebar.kategori"
                    :options="sidebar.kategoriler"
                    label="label"
                    input-id="calendar"
                >
                  <template
                      #selected-option-container="{ option }"
                  >
                    <div
                        class="rounded-circle d-inline-block m-auto"
                        :class="`bg-${option.color}`"
                        style="height:10px;width:10px"
                    />
                    <span class="sidebar-v-select"> {{ option.label }}</span>
                  </template>
                  <template #option="{ color, label }">
                    <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Başlangıç Tarihi ve Saati"
                  label-for="basicInput"
              >
                <flat-pickr
                    v-model="sidebar.baslangic"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Bitiş Tarihi ve Saati"
                  label-for="basicInput"
              >
                <flat-pickr
                    v-model="sidebar.bitis"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
            <!--            <b-col cols="12">-->
            <!--              <b-form-group>-->
            <!--                <b-form-checkbox-->
            <!--                  v-model="sidebar.tumGun"-->
            <!--                  name="check-button"-->
            <!--                  switch-->
            <!--                  inline-->
            <!--                >-->
            <!--                  Tüm Gün-->
            <!--                </b-form-checkbox>-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->
            <b-col cols="12">
              <b-form-group
                  label="Not"
                  label-for="event-description"
              >
                <b-form-textarea
                    id="event-description"
                    v-model="sidebar.not"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr/>
              <div class="d-flex mt-2">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    @click="!isEdit ? sideBarSubmit() : sideBarUpdate()"
                    :disabled="!sidebar.baslangic || !sidebar.bitis"
                >
                  {{ !isEdit ? 'Kaydet' : 'Güncelle' }}
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    :variant="!isEdit ? 'outline-secondary':'outline-danger'"
                    @click="!isEdit ? resetSideBar() : etkinlikDelete()"
                >
                  {{ !isEdit ? 'Sıfırla' : 'Sil' }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  VBToggle,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BFormCheckboxGroup,
  BImg,
  BLink,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    ToastificationContent,
    BImg,
    BFormCheckboxGroup,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    VSelect,
    BRow,
    BLink,
    BCol,
    BFormGroup,
    BFormInput,
    flatPickr,
    BSidebar,
    FullCalendar,
  },
  data() {
    return {
      kategoriCheck: [],
      selectedKategori: ['Randevu', 'Toplantı', 'Ödeme', 'Tahsilat', 'Finansal'],
      checkAll: true,
      postDate: null,
      editID: null,
      isEdit: false,
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      sidebar: {
        baslik: null,
        not: null,
        tumGun: false,
        baslangic: null,
        bitis: null,
        kategori: {
          label: 'Randevu',
          key: 'Randevu',
          color: 'success',
        },
        kategoriler: [
          {
            label: 'Randevu',
            color: 'success',
          },
          {
            label: 'Toplantı',
            color: 'warning',
          },
          {
            label: 'Ödeme',
            color: 'danger',
          },
          {
            label: 'Tahsilat',
            color: 'primary',
          },
          {
            label: 'Finansal',
            color: 'info',
          },

        ],
      },
      isSidebar: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        locale: 'tr',
        height: 750,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        initialView: 'dayGridMonth',
        dateClick: this.dateClick,
        eventClick: this.eventClick,
        eventDrop: this.eventDrop,
        eventClassNames: this.eventClassNames,
        datesSet: this.dateSet,
        editable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        fixedWeekCount: false,
        dayMaxEvents: 3,
        moreLinkText: 'Daha fazla etkinlik',
        noEventsContent: 'Etkinlik Bulunamadı',
        buttonText: {
          today: 'Bugün',
          month: 'Aylık',
          week: 'Haftalık',
          day: 'Günlük',
          list: 'Liste',
        },
        allDayText: 'Tüm Gün',
        navLinks: true,
        calendarsColor: {
          'Toplantı': 'warning',
          'Randevu': 'success',
          'Ödeme': 'danger',
          'Tahsilat': 'primary',
          'Finansal': 'info',
          color: '#378006',
        },
        eventColor: '#378006',
        events: this.getEvent(),
      },
    }
  },
  computed: {},
  methods: {
    dateSet(val) {
      this.postDate = val.startStr
      const yil = val.startStr.slice(0, 4)
      const ay = parseInt(val.startStr.slice(5, 7))
      const sonrakiAy = ay + 1
      const self = this
      const data = {
        crm_token: localStorage.getItem('buroUserToken'),
        serviceName: 'dashBoard',
        yil,
        ay: sonrakiAy < 10 ? `0${sonrakiAy}` : sonrakiAy,
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          store.commit({
            type: 'DASH_FINANS_ACTION',
            payload: res.kasaOzeti,
          })
          store.commit('setToken', res.userToken)
          const events = res.ajanda
          const calendarApi = self.$refs.refCalendar.getApi()
          calendarApi.getEventSources()
              .forEach(source => {
                source.remove()
              })
          let filteredEvents = []
          if (self.selectedKategori) {
            Object.values(self.selectedKategori)
                .forEach(veri => {
                  filteredEvents = filteredEvents.concat(events?.filter(item => item.kategori === veri))
                })
          }
          filteredEvents.forEach(item => {
            if (item?.baslangic) {
              item.start = self.toIsoDate(item.baslangic)
            }
            if (item?.bitis) {
              item.end = self.toIsoDate(item.bitis)
            }
          })
          calendarApi.addEventSource(events)
        },
        dataType: 'json',
      })
    },
    getEvent(filter) {
      const yil = this.postDate?.slice(0, 4)
      const ay = parseInt(this.postDate?.slice(5, 7))
      const sonrakiAy = ay + 1
      const self = this
      const data = {
        crm_token: localStorage.getItem('buroUserToken'),
        serviceName: 'dashBoard',
        [this.postDate ? 'yil' : '']: yil,
        [this.postDate ? 'ay' : '']: sonrakiAy < 10 ? `0${sonrakiAy}` : sonrakiAy,
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          let events = res.ajanda
          const calendarApi = self.$refs.refCalendar.getApi()
          calendarApi.getEventSources()
              .forEach(source => {
                source.remove()
              })
          let filteredEvents = []
          if (filter) {
            Object.values(filter)
                .forEach(veri => {
                  filteredEvents = filteredEvents.concat(events.filter(item => item.kategori === veri))
                })
          }
          filteredEvents.forEach(item => {
            // if (item.tumGun === '1') {
            //     item.allDay = true
            // }
            if (item?.baslangic) {
              item.start = self.toIsoDate(item.baslangic)
            }
            if (item?.bitis) {
              item.end = self.toIsoDate(item.bitis)
            }
          })
          calendarApi.addEventSource(events)
        },
        dataType: 'json',
      })
    },
    etkinlikDelete() {
      store.dispatch('etkinlikDelete', this.editID)
          .then((res, position = 'bottom-right') => {
            this.getEvent(this.selectedKategori)
            this.$root.$emit('bv::toggle::collapse', 'etkinlik-sidebar')
            this.resetSideBar()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    sideBarSubmit() {
      store.dispatch('etkinlikSave', {
        baslik: this.sidebar.baslik,
        baslangic: this.sidebar.baslangic,
        bitis: this.sidebar.bitis,
        not: this.sidebar.not,
        kategori: this.sidebar.kategori.label,
        tumGun: 0,
        // tumGun: this.sidebar.tumGun,
      })
          .then((res, position = 'bottom-right') => {
            this.getEvent(this.selectedKategori)
            this.$root.$emit('bv::toggle::collapse', 'etkinlik-sidebar')
            this.resetSideBar()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    sideBarUpdate() {
      store.dispatch('etkinlikUpdate', {
        baslik: this.sidebar.baslik,
        baslangic: this.sidebar.baslangic,
        bitis: this.sidebar.bitis,
        not: this.sidebar.not,
        kategori: this.sidebar.kategori.label,
        tumGun: 0,
        // tumGun: this.sidebar.tumGun,
        ajandaID: this.editID,
      })
          .then((res, position = 'bottom-right') => {
            this.getEvent(this.selectedKategori)
            this.$root.$emit('bv::toggle::collapse', 'etkinlik-sidebar')
            this.resetSideBar()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    dateClick(val) {
      const t = this
      this.$root.$emit('bv::toggle::collapse', 'etkinlik-sidebar')
      t.sidebar.baslangic = val.dateStr
    },
    eventClick(val) {
      // val.event.allDay = val.event.extendedProps.tumGun === '1'
      const t = this
      t.isEdit = true
      t.editID = val.event.extendedProps.ajandaID
      t.$root.$emit('bv::toggle::collapse', 'etkinlik-sidebar')
      t.sidebar.baslangic = val.event.start
      t.sidebar.bitis = val.event.end
      t.sidebar.baslik = val.event.extendedProps.baslik
      t.sidebar.not = val.event.extendedProps.kayitNotu
      // t.sidebar.tumGun = val.event.extendedProps.tumGun === '1'
      const tur = this.sidebar.kategoriler.find(item => item.label === val.event.extendedProps.kategori)
      t.sidebar.kategori = tur
    },
    eventDrop(val) {
      const start = val.event._instance.range.start.toLocaleString('tr-TR')
      const end = val.event._instance.range.end.toLocaleString('tr-TR')
      const baslangic = `${start.slice(6, 10)}-${start.slice(3, 5)}-${start.slice(0, 2)}`
      const bitis = `${end.slice(6, 10)}-${end.slice(3, 5)}-${end.slice(0, 2)}`
      const baslangicSaat = val.event.start?.getHours()
      const baslangicDakika = val.event.start?.getMinutes()
      const bitisSaat = val.event.end?.getHours()
      const bitisDakika = val.event.end?.getMinutes()
      const baslangicDate = `${baslangic} ${baslangicSaat}:${baslangicDakika}`
      const bitisDate = `${bitis} ${bitisSaat}:${bitisDakika}`
      store.dispatch('etkinlikTarihUpdate', {
        baslangic: baslangicDate,
        bitis: bitisDate,
        ajandaID: val.event.extendedProps.ajandaID,
      })
          .then((res, position = 'bottom-right') => {
            this.getEvent(this.selectedKategori)
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    eventClassNames(val) {
      const color = this.calendarOptions.calendarsColor[val.event._def.extendedProps.kategori]
      return `bg-light-${color} border border-${color}`
    },
    toIsoDate(tarih) {
      const date = new Date(tarih)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1)
          .padStart(2, '0')
      const day = String(date.getDate())
          .padStart(2, '0')
      const hours = String(date.getHours())
          .padStart(2, '0')
      const minutes = String(date.getMinutes())
          .padStart(2, '0')
      const seconds = String(date.getSeconds())
          .padStart(2, '0')
      const isoDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
      return isoDate
    },
    resetSideBar() {
      const t = this
      t.sidebar.baslik = null
      t.sidebar.not = null
      // t.sidebar.tumGun = false
      t.sidebar.baslangic = null
      t.sidebar.bitis = null
      t.sidebar.kategori = {
        label: 'Randevu',
        key: 'Randevu',
        color: 'success',
      }
    },
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  watch: {
    isSidebar(val) {
      if (val === false) {
        this.sidebar.bitis = null
        this.isEdit = false
        this.editID = null
        this.resetSideBar()
      }
    },
    checkAll(newValue) {
      if (newValue) {
        this.selectedKategori = this.sidebar.kategoriler.map(kategori => kategori.label)
      } else {
        this.selectedKategori = []
      }
    },
    selectedKategori(newValue) {
      this.getEvent(newValue)
      if (newValue.length === 0) {
        this.getEvent()
      }
    },
  },
  created() {
  },
}
</script>
<style>
@media (max-width: 400px) {
  .fc-button-group {
    display: none !important;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.sidebar-v-select {
  margin: auto 5px;
}

.fc-daygrid-event b {
  white-space: normal !important;
}

</style>
