<template>
  <section id="dash-selection">
    <b-row fluid>
      <b-col cols="12">
        <b-card-actions
            ref="first-card"
            id="first-card"
            @refresh="refreshStop('first-card')"
        >
          <b-row class="mt-2">
            <b-col class="media-responsive">
              <b-media no-body @click="$router.push({name: 'satislar'})">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      variant="light-success"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="fa-solid fa-file-invoice" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{
                      Number(finans.satisFaturalari)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Satış Faturaları
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="media-responsive">
              <b-media no-body @click="$router.push({name: 'alislar'})">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      variant="light-info"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{
                      Number(finans.alisFaturalari)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Alış Faturaları
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="media-responsive">
              <b-media no-body id="cari-media" @click="$router.push({name: 'kasa'})">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      class="pull-up"
                      variant="light-success"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{
                      Number(finans.tahsilatlar)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Tahsilatlar
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="media-responsive">
              <b-media no-body id="cari-media" @click="$router.push({name: 'kasa'})">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      class="pull-up"
                      variant="light-danger"
                  >
                    <font-awesome-icon icon="fa-solid fa-minus" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{
                      Number(finans.odemeler)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Ödemeler
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="media-responsive">
              <b-media no-body id="cari-media" @click="$router.push({name: 'kasa'})">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      class="pull-up"
                      variant="light-success"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{
                      Number(finans.bekleyenFaturaTahsilatlari)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Bekleyen Tahsilat
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="media-responsive">
              <b-media no-body id="cari-media" @click="$router.push({name: 'kasa'})">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      class="pull-up"
                      variant="light-danger"
                  >
                    <font-awesome-icon icon="fa-solid fa-minus" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{
                      Number(finans.bekleyenFaturaOdemeleri)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Bekleyen Ödemeler
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
      <b-col xl="4" lg="12" sm="12" cols="12">
        <b-card-actions
            ref="cardAction"
            class="card-revenue-budget"
            title="Aylık Finans Raporu"
            @refresh="refreshStop('cardAction')"
        >
          <b-row class="mb-sm-2 mb-2 mt-1">
            <b-col md="3" lg="3" xl="6" cols="6" class="media-responsive">
              <b-media no-body @click="$router.push('/cariListe/0')">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      variant="light-success"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="fa-solid fa-users" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{ info.borcluKartlar || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Borçlu Cari Kartlar
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" lg="3" xl="6" cols="6" class="media-responsive">
              <b-media no-body @click="$router.push('/cariListe/0')">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      variant="light-warning"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="fa-solid fa-users" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{ info.alacakliKartlar || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Alacaklı Cari Kartlar
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" lg="3" xl="6" cols="6" class="media-responsive mt-xl-1 mt-sm-0">
              <b-media no-body @click="$router.push('/satislar')">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      variant="light-info"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{ info.acikSatisFaturalari || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Açık Satış Faturaları
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" lg="3" xl="6" cols="6" class="media-responsive mt-xl-1 mt-sm-0"
            >
              <b-media no-body @click="$router.push('/alislar')">
                <b-media-aside
                    class="media-aside-p"
                >
                  <b-avatar
                      size="48"
                      variant="light-danger"
                      class="pull-up"
                  >
                    <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0 finans">
                    {{ info.acikAlisFaturalari || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Açık Alış Faturaları
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <hr/>
          <chartjs-component-horizontal-bar-chart
              :height="400"
              :data="lineBarChartData"
              :options="horizontalBarChart.options"
          />
        </b-card-actions>
      </b-col>
      <b-col xl="8" lg="12" sm="12" cols="12" class="dashboard-calendar">
        <calendar/>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BCard,
  BButton,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import calendar from './calendar/Calendar.vue'
import store from '@/store'
import ChartjsComponentHorizontalBarChart from './ChartjsComponentHorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCardBody,
    ChartjsComponentHorizontalBarChart,
    BCardHeader,
    calendar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BCard,
    BButton,
    BDropdown,
    BCardActions,
    VueApexCharts,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      flag: null,
      horizontalBarChart: {
        options: {
          color: ['#617A55', '#F79540'],
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                return `${value.toLocaleString()} TL`
              },
            },
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: '#87CBB9',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: 'transparent',
                  borderColor: 'transparent',
                  color: '#F1F6F9',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: '#2A2F4F',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#2A2F4F',
                },
              },
            ],
          },
        },
        data: {
          labels: ['Kasa girişi', 'Tahsilatlar', 'Satış faturaları', 'Kasa Çıkışı', 'Ödemeler', 'Alış faturaları'],
          datasets: [
            {
              data: [720, 350, 470, 580, 230, 460, 120],
              backgroundColor: ['#00ABB3', '#59C1BD', '#87CBB9', '#975C8D', '#F06292', '#FF97C1'],
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      },
    }
  },
  computed: {
    aylikGrafik() {
      return store.state.aylikGrafik
    },
    lineBarChartData() {
      let anaVeri = { ...this.horizontalBarChart.data }
      anaVeri.datasets[0].data = [
        this.aylikGrafik.kasaGirisi,
        this.aylikGrafik.tahsilatlar,
        this.aylikGrafik.satisFaturalari,
        this.aylikGrafik.kasaCikisi,
        this.aylikGrafik.odemeler,
        this.aylikGrafik.alisFaturalari,
      ]
      return anaVeri
    },
    info() {
      return store.getters.DASH_INFO
    },
    finans() {
      return store.getters.DASH_FINANS
    },
  },
  created() {
    this.lineBarChartData
  },
  methods: {
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
  },
}
</script>
<style>
#dash-selection #first-card .card-header {
  display: none !important;
}

#dash-selection .pull-up {
  transition: 300ms;
}
</style>
<style scoped>
.media {
  cursor: pointer;
}
</style>
